<template>
  <svg viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.26002 7.19484L7.69471 3.76066L6.5687 2.63465L4.99453 4.20882C4.97576 4.22763 4.95346 4.24255 4.92892 4.25273C4.90437 4.26291 4.87806 4.26815 4.85149 4.26815C4.82491 4.26815 4.7986 4.26291 4.77405 4.25273C4.74951 4.24255 4.72721 4.22763 4.70844 4.20882L4.42234 3.92272C4.34322 3.84361 4.34322 3.71549 4.42234 3.63663L5.99651 2.06246L5.13797 1.20366C4.82175 0.887447 4.30928 0.887447 3.99307 1.20366L1.70353 3.4932C1.38758 3.80941 1.38732 4.32188 1.70353 4.63809L4.26002 7.19484ZM14.1744 4.21392C14.6532 3.73514 14.6529 2.95903 14.1744 2.48025L13.0195 1.3254C12.5407 0.846613 11.7644 0.846613 11.2853 1.3254L10.1108 2.49964L12.9999 5.38868L14.1744 4.21392ZM9.53328 3.07745L1.95339 10.6563L1.4746 13.3984C1.41004 13.7682 1.73212 14.0902 2.10218 14.0252L4.84447 13.5443L12.4223 5.96623L9.53328 3.07745ZM14.2964 10.3621L13.4378 9.50352L11.8637 11.0777C11.7845 11.1568 11.6564 11.1568 11.5776 11.0777L11.2915 10.7916C11.2126 10.7125 11.2126 10.5844 11.2915 10.5055L12.8656 8.93132L11.7391 7.8048L8.30442 11.239L10.8619 13.7962C11.1781 14.1124 11.6906 14.1124 12.0068 13.7962L14.2964 11.507C14.6126 11.1907 14.6126 10.6783 14.2964 10.3621Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconDraw'
}
</script>
